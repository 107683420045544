<template>
  <div class="home">
    <div class="bg--main bg--main-fitness">
      <div class="container container--header">
        <div class="header">
          <router-link to="/" class="header__logo" />
          <div class="header-block">
            <p class="header-block__name">
              Добрый день!
            </p>
            <img src="@/assets/icons/name_icon.png"
              srcset="@/assets/icons/name_icon@2x.png 2x" alt="" class="header-block__icon"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="meditation">
          <h1 class="meditation__tit">
            Видеогайд «Красивая осанка и здоровая спина»
          </h1>
          <div class="meditation-blocks">
            <a
              href="#" class="meditation-block meditation-block--fitness"
              v-for="(video, index) in videos"
              :key="video.id"
              @click.prevent="selectTrack(video, index)"
            >
              <div class="meditation-block__img-block meditation-block__img-block--fitness">
                  <img :src="video.image['2x'].replace('https://79f714d8-9b86-4071-b1da-ed403b2e9bbb.akamaized.net/', 'https://lerchek.fit/')" alt="img" class="meditation-block__img meditation-block__img--fitness">
                  <div class="meditation-block__info-play meditation-block__info-play--fitness"></div>
              </div>
              <div class="meditation-block__info meditation-block__info--fitness">
                <div class="meditation-block__info-time">
                  {{ formatTime(video.duration) }}
                </div>
                <div class="meditation-block__info-name">
                  {{ video.name }}
                </div>
                <div class="meditation-block__desc" v-html="video.description">
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
  </div>
  <div class="player" v-if="video" @click.prevent="video = null">
    <div class="player-block"
      @click.stop
    >
        <iframe allow="autoplay; fullscreen" allowfullscreen :src="video.url + '&autoplay=1'" class="vimeo" width="100%" height="100%"></iframe>
        <button class="player-block__back" @click.prevent="video = null"></button>
        <button class="player-block__like"></button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      videos: [],
      video: null,
      currentTrack: null,
    };
  },
  components: {
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$request.get('/api/guide/video', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.data.success) {
        this.$router.replace('/login');
      } else {
        this.videos = response.data.result.videos;
      }
    },
    selectTrack(video) {
      this.video = video;
    },
    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let sec = Math.floor(seconds - minutes * 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      sec = sec < 10 ? `0${sec}` : sec;
      return `${minutes}:${sec}`;
    },
  },
  computed: {
    bg() {
      if (!this.video) {
        return null;
      }
      return this.video.image['2x'];
    },
  },
};
</script>
<style lang="scss">
.vimeo {
  width: 100%;
  height: 90vh;
  @media (max-width: 768px) {
    height: auto;
  }
}
audio {
  display: none;
}
.meditation-block {
  &__desc {
    color: #000;
    font-size: 1.5rem;
    line-height: 1.3;
    margin-top: 2rem;
    max-width: 80%
  }
  &__img-block {
    overflow: hidden;
    & img {
      transition: .3s ease transform;
    }
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
    & .meditation-block__info-play:after {
      transform: translate(-50%, -50%) scale(0);
    }
  }
  &__info-play {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      width: 8rem;
      height: 8rem;
      background: rgba(255,255,255,.2);
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: .3s ease transform;
      @media (max-width: 768px) {
        width: 6rem;
        height: 6rem;
      }
    }
    &--fitness {
      position: absolute;
    }
  }
}
.meditation-block__img {
  @media (max-width: 768px) {
    top: -2rem;
  }
  &--fitness {
    top: 0;
  }
}
.player {
  display: flex;
  &-block {
    width: 83.4rem;
    box-sizing: border-box;
    max-height: 90vh;
    @media (max-width: 768px) {
      width: 80vw;
      height: auto;
      &__name {
        padding-top: 15rem;
      }
    }
    &__like {
      display: none;
    }
  }
}
.player-block__back {
  top: 10rem;
}
.player-block__button {
  transition: .3s ease all;
  &--active,
  &:hover {
    transform: scale(1.05);
    filter: brightness(1.3);
  }
}
input[type=range] {
  -webkit-appearance: none;
  margin: 11px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-thumb {
  width: .8rem;
  height: .8rem;
  background: #fff !important;
  -webkit-appearance: none;
  border-radius: 1rem;
  cursor: pointer;
  margin-top: -14px;
  transition: .3s ease all;
  &:hover {
    transform: scale(2);
  }
}

input[type=range]::-moz-range-thumb {
  width: .8rem;
  height: .8rem;
  background: #fff;
  border-radius: 1rem;
  background: #ffffff;
  cursor: pointer;
  box-shadow: #fff;
  transition: .3s ease all;
  &:hover {
    transform: scale(2);
  }
}

input[type=range]::-ms-thumb {
  width: .8rem;
  height: .8rem;
  background: #fff;
  border-radius: 1rem;
  background: #ffffff;
  cursor: pointer;
  box-shadow: #fff;
  transition: .3s ease all;
  &:hover {
    transform: scale(2);
  }
}
</style>
